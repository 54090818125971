var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"common-section stay__tuned--section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 left-inner"},[_c('div',{staticClass:"heading-container"},[_c('div',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t("stay_in_touch.title"))+" ")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("stay_in_touch.content"))+" ")])])]),_c('div',{class:[
          'col-12 col-lg-6 right-inner',
          { 'align-items-center': !_vm.showTerms }
        ]},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"subscribe-email"},[_c('div',{class:[
                'email-container',
                {
                  focused: _vm.isFocused,
                  pressed: _vm.isPressed,
                  error: _vm.emailError || _vm.checkboxError,
                  success: _vm.emailSent
                }
              ]},[_c('img',{staticClass:"icon",attrs:{"src":_vm.emailIcon,"alt":"email"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","id":"email","placeholder":_vm.$t('stay_in_touch.email.placeholder'),"required":"","tabindex":"0"},domProps:{"value":(_vm.email)},on:{"focus":function($event){_vm.isFocused = true},"blur":function($event){_vm.isFocused = false},"mousedown":function($event){_vm.isPressed = true},"mouseup":function($event){_vm.isPressed = false},"touchstart":function($event){_vm.isPressed = true},"touchend":function($event){_vm.isPressed = false},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('LxpButton',{staticClass:"d-none d-md-block",attrs:{"type":"submit","variant":_vm.emailSent ? 'success' : 'accent'}},[_vm._v(" "+_vm._s(_vm.emailSent ? _vm.$t("stay_in_touch.email.added") : _vm.$t("stay_in_touch.email.button"))+" ")])],1),(_vm.emailError)?_c('div',{staticClass:"email-error"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),(_vm.showTerms)?_c('div',{class:[
                'email-checkbox',
                { 'checkbox-error': _vm.checkboxError && !_vm.isChecked }
              ]},[(_vm.checkboxError && !_vm.isChecked)?_c('span',{staticClass:"highlighter"}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isChecked),expression:"isChecked"}],attrs:{"type":"checkbox","id":"checkbox","required":""},domProps:{"checked":Array.isArray(_vm.isChecked)?_vm._i(_vm.isChecked,null)>-1:(_vm.isChecked)},on:{"change":function($event){var $$a=_vm.isChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isChecked=$$c}}}}),_c('label',{attrs:{"for":"checkbox"}},[_vm._v(" "+_vm._s(_vm.$t("stay_in_touch.email.condition.agree"))+" "),_c('i18next',{staticClass:"check",attrs:{"path":"stay_in_touch.email.condition.click","tag":"span"}},[_c('a',{attrs:{"href":"/terms-and-conditions","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("stay_in_touch.email.condition.click_0"))+" ")]),_c('a',{attrs:{"href":"/terms-and-conditions","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("stay_in_touch.email.condition.click_1"))+" ")])])],1)]):_vm._e(),(_vm.checkboxError)?_c('div',{staticClass:"email-error"},[_vm._v(" "+_vm._s(_vm.checkboxErrorMsg)+" ")]):_vm._e(),_c('LxpButton',{staticClass:"d-block d-md-none",attrs:{"type":"submit","variant":_vm.emailSent ? 'success' : 'primary'}},[_vm._v(" "+_vm._s(_vm.emailSent ? _vm.$t("stay_in_touch.email.added") : _vm.$t("stay_in_touch.email.button"))+" ")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }