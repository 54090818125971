<template>
  <section class="common-section stay__tuned--section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 left-inner">
          <div class="heading-container">
            <div class="heading">
              {{ $t("stay_in_touch.title") }}
            </div>
            <div class="content">
              {{ $t("stay_in_touch.content") }}
            </div>
          </div>
        </div>
        <div
          :class="[
            'col-12 col-lg-6 right-inner',
            { 'align-items-center': !showTerms }
          ]"
        >
          <form @submit.prevent="submitForm" novalidate>
            <div class="subscribe-email">
              <div
                :class="[
                  'email-container',
                  {
                    focused: isFocused,
                    pressed: isPressed,
                    error: emailError || checkboxError,
                    success: emailSent
                  }
                ]"
              >
                <img :src="emailIcon" alt="email" class="icon" />
                <input
                  type="email"
                  v-model="email"
                  id="email"
                  :placeholder="$t('stay_in_touch.email.placeholder')"
                  required
                  @focus="isFocused = true"
                  @blur="isFocused = false"
                  @mousedown="isPressed = true"
                  @mouseup="isPressed = false"
                  @touchstart="isPressed = true"
                  @touchend="isPressed = false"
                  tabindex="0"
                />
                <LxpButton
                  class="d-none d-md-block"
                  type="submit"
                  :variant="emailSent ? 'success' : 'accent'"
                >
                  {{
                    emailSent
                      ? $t("stay_in_touch.email.added")
                      : $t("stay_in_touch.email.button")
                  }}
                </LxpButton>
              </div>
              <div class="email-error" v-if="emailError">
                {{ errorMsg }}
              </div>
              <div
                :class="[
                  'email-checkbox',
                  { 'checkbox-error': checkboxError && !isChecked }
                ]"
                v-if="showTerms"
              >
                <span
                  class="highlighter"
                  v-if="checkboxError && !isChecked"
                ></span>
                <input
                  type="checkbox"
                  v-model="isChecked"
                  id="checkbox"
                  required
                />
                <label for="checkbox">
                  {{ $t("stay_in_touch.email.condition.agree") }}
                  <i18next
                    path="stay_in_touch.email.condition.click"
                    tag="span"
                    class="check"
                  >
                    <a href="/terms-and-conditions" target="_blank">
                      {{ $t("stay_in_touch.email.condition.click_0") }}
                    </a>
                    <a href="/terms-and-conditions" target="_blank">
                      {{ $t("stay_in_touch.email.condition.click_1") }}
                    </a>
                  </i18next>
                </label>
              </div>
              <div class="email-error" v-if="checkboxError">
                {{ checkboxErrorMsg }}
              </div>
              <LxpButton
                class="d-block d-md-none"
                type="submit"
                :variant="emailSent ? 'success' : 'primary'"
              >
                {{
                  emailSent
                    ? $t("stay_in_touch.email.added")
                    : $t("stay_in_touch.email.button")
                }}
              </LxpButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { LxpButton } from "didactica";
export default {
  components: { LxpButton },
  props: {
    showTerms: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      emailIcon: require("@/assets/images/landing/stay-tuned/email.svg"),
      email: "",
      errorMsg: "",
      checkboxErrorMsg: "",
      isChecked: false,
      emailSent: false,
      emailError: false,
      checkboxError: false,
      isFocused: false,
      isPressed: false
    };
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    submitForm() {
      this.errorMsg = "";
      this.checkboxErrorMsg = "";
      this.emailError = false;
      this.checkboxError = false;
      this.emailSent = false;
      let isValid = true;

      if (!this.validateEmail(this.email)) {
        this.emailError = true;
        this.errorMsg = this.$t("stay_in_touch.email.error");
        isValid = false;
      }
      if (!this.isChecked) {
        this.checkboxError = true;
        this.checkboxErrorMsg = this.$t("stay_in_touch.email.checkbox_error");
        isValid = false;
      }
      if (!isValid) {
        return;
      }
      this.$store
        .dispatch("subscribeEmail", { email_id: this.email })
        .then(() => {
          this.emailSent = true;
          setTimeout(() => {
            this.emailSent = false;
            this.resetForm();
          }, 3000);
        })
        .catch(() => {
          this.emailSent = false;
          this.emailError = true;
        });
    },
    resetForm() {
      this.email = "";
      this.errorMsg = "";
      this.checkboxErrorMsg = "";
      this.isChecked = false;
      this.emailError = false;
      this.checkboxError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.stay__tuned--section {
  background: radial-gradient(
    155.36% 130.15% at 27.34% 62.76%,
    #6738ef 0%,
    #0081ff 100%
  );
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  font-family: $font-family;
  text-align: left;
  .heading-container {
    @include flex-column-start;
    .heading {
      @include h3;
      font-weight: 500;
      color: $brand-neutral-0;
    }
    .content {
      margin-top: 8px;
      @include h6;
      color: $brand-primary-50;
    }
  }
  .subscribe-email {
    @include flex-column-start;
    .email-container {
      border: 2px solid transparent;
      border-radius: 100px;
      background-color: $brand-primary-75;
      padding: 23px 177px 23px 64px;
      width: 515px;
      height: 70px;
      position: relative;
      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 24px;
      }
      input {
        margin: 0;
        padding: 0;
        border: none;
        width: 100%;
        color: $brand-neutral-700;
        background-color: transparent;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $brand-neutral-300;
        }
      }
      .btn {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 161px;
        height: 54px;
      }
      &:hover {
        background-color: $brand-primary-100;
      }
      &.focused {
        border-color: $brand-primary-400;
        background-color: $brand-primary-100;
      }
      &.pressed {
        border-color: $brand-primary-400;
        background-color: $brand-primary-100;
      }
      &.error {
        border-color: $brand-error-400;
        background-color: $brand-primary-75;
      }
      &.success {
        border-color: transparent;
        background-color: $brand-primary-75;
        input {
          color: $brand-neutral-300;
        }
        .btn {
          width: 122px;
        }
      }
    }
    .email-error {
      margin-top: 4px;
      margin-left: 40px;
      @include label-medium;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: $brand-primary-50;
    }
    .email-checkbox {
      margin-top: 18px;
      display: flex;
      @include body-medium;
      color: $brand-primary-50;
      padding-left: 12px;
      position: relative;
      input {
        display: none;
        z-index: 1;
        &:checked {
          + label {
            &:after {
              display: block;
            }
          }
        }
      }
      label {
        margin: 0;
        cursor: pointer;
        z-index: 1;
        &:before {
          content: "";
          width: 16px;
          height: 16px;
          border: 2px solid $brand-primary-50;
          padding: 0;
          display: inline-block;
          border-radius: 3px;
          margin-right: 8px;
          position: relative;
          top: 5px;
          opacity: 0.9;
        }
        &:after {
          content: "";
          display: none;
          position: absolute;
          top: 7px;
          left: 18px;
          width: 5px;
          height: 9px;
          border-style: solid;
          border-color: $brand-primary-50;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
        span {
          display: block;
          padding-left: 28px;
          a {
            color: $brand-primary-50;
            text-decoration: underline;
          }
        }
      }
      &.checkbox-error {
        label {
          &:before {
            border-color: $brand-error-400;
          }
          &:after {
            border-color: $brand-error-400;
          }
        }
      }
      .highlighter {
        position: absolute;
        background-color: $brand-error-100;
        left: 8px;
        top: 1px;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        padding: 0 !important;
        z-index: 0;
      }
    }
  }
  .right-inner {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1199px) {
  .stay__tuned--section {
    .subscribe-email {
      .email-container {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .stay__tuned--section {
    .heading-container {
      align-items: center;
      text-align: center;
    }
    .right-inner {
      justify-content: center;
      margin-top: 24px;
    }
    .subscribe-email {
      .email-container {
        width: 515px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .stay__tuned--section {
    .right-inner {
      form {
        width: 100%;
      }
    }
    .subscribe-email {
      > .lxp-button {
        width: 100%;
        margin-top: 24px;
        height: 54px;
      }
      .email-container {
        width: 100%;
        padding: 14px 24px 14px 64px;
        height: 56px;
      }
      .email-checkbox {
        padding-left: 8px;
        label {
          padding-left: 32px;
          &:before {
            position: absolute;
            top: 4px;
            left: 12px;
          }
          &:after {
            left: 18px;
            top: 6px;
          }
          span {
            padding-left: 0;
          }
        }
        .highlighter {
          top: 0px;
        }
      }
    }
  }
}
</style>
