<template>
  <div>
    <div class="page-loader" v-if="isLoading">
      <LxpLoader />
    </div>
    <LandingPageLayout class="partnerLanding" v-else>
      <div class="h-100">
        <LxpBreadcrumb
          class="learning-space__breadcrumb"
          :items="items"
          @click="navigateToLink"
        ></LxpBreadcrumb>
        <div class="learning-space__error" v-if="hasError">
          <LearningSpace
            :hasError="true"
            @click-empty-space-cta="onEmptyStateCTA"
            :spaceObj="learningSpaceInfo"
          />
        </div>
        <div class="learning-space__wrapper mb-4" v-else>
          <div class="ls-wrapper__main">
            <LearningSpace
              :key="key"
              :spaceObj="learningSpaceInfo"
              :urlList="getURLList"
              :initiatives="initiatives"
              :sections="sections"
              :tags="tags"
              :filterOptions="filterOptions"
              :featuredResources="featuredResources"
              @card-click="onCardClick"
              @click-empty-state-cta="onEmptyStateCTA"
            />
          </div>
        </div>
      </div>
      <StayTuned />
    </LandingPageLayout>
  </div>
</template>
<script>
import LandingPageLayout from "../components/Layout/LandingPageLayout.vue";
import StayTuned from "../components/Landing/StayTuned.vue";
import { LxpBreadcrumb, LxpLoader } from "didactica";
import { LearningSpace } from "didactica";
import { mapGetters } from "vuex";
//import LayoutManager from "../components/Layout/LayoutManager";

export default {
  components: {
    LxpLoader,
    LandingPageLayout,
    LearningSpace,
    LxpBreadcrumb,
    StayTuned
  },
  data() {
    return {
      items: [
        {
          icon: null,
          text: this.$t("general.menu.home"),
          to: { name: "Home" }
        },
        {
          icon: null,
          text: this.$t("general.menu.discovery"),
          to: { name: "discovery" }
        },
        {
          text: this.$route.params.org.toUpperCase(),
          active: true
        }
      ],
      isLoading: true,
      learningSpaceInfo: {},
      initiatives: [],
      featuredResources: [],
      sections: [],
      tags: [],
      filterOptions: [],
      key: 0,
      hasError: false,
      org: ""
    };
  },
  computed: {
    ...mapGetters(["allConfig", "language"]),
    currentOrg() {
      return this.$route.params.org;
    },
    getURLList() {
      return {
        LIST_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/discovery-paginated?org=${this.currentOrg}&language=${this.language}`
        },
        FILTER_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/tags/filter-paginated/?tags=[{tagids}]&language={lang}`
        },
        LIST_FEATURED_RESOURCES: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/discovery-paginated?org=${this.currentOrg}&enforce_org_in_id=true`
        }
      };
    }
  },

  created() {
    this.org = this.$route.params.org;
    this.getLearningSpaceInfo();
  },
  methods: {
    getLearningSpaceInfo() {
      let params = {
        org: this.org
      };
      this.$store
        .dispatch("getPartnersDetail", params)
        .then(response => {
          this.learningSpaceInfo = response;
          this.getSections();
          this.key++;
        })
        .catch(() => {
          this.isLoading = false;
          this.learningSpaceInfo = { name: this.currentOrg };
          this.hasError = true;
        });
    },
    getFeaturedResources() {
      let params = {
        org: this.org
      };
      this.$store
        .dispatch("getFeatureResource", params)
        .then(response => {
          this.featuredResources = response;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getInitiatives() {
      let params = {
        org: this.org
      };
      this.$store
        .dispatch("getInitiativesInfo", params)
        .then(response => {
          this.initiatives = response;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getSections() {
      let params = {
        org: this.org
      };
      this.$store
        .dispatch("getSectionsInfo", params)
        .then(response => {
          this.sections = response;
          this.getInitiatives();
          this.getTags();
          this.getFeaturedResources();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getTags() {
      let params = {
        org: this.org
      };
      this.$store
        .dispatch("getTagsInfo", params)
        .then(response => {
          this.tags = response.results;
          this.filterOptions = response.results;
        })
        .catch(() => {
          this.isLoading = false;
          this.hasError = true;
        });
    },
    onCardClick(c) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: c.id, from: "" },
        query: {
          source: c.source,
          org: this.currentOrg,
          from: "learning-space"
        }
      });
    },
    onEmptyStateCTA() {
      this.$router.push({ name: "course-discovery" });
    },
    navigateToLink(item) {
      this.$router.push(item.to);
    }
  }
};
</script>
<style lang="scss" scoped>
.partnerLanding ::v-deep .lp-header {
  background: #375af6;
}

.learning-space__wrapper {
  padding: 0px 32px;
  margin-left: 10px;

  .ls-wrapper__main {
    background: $brand-neutral-0;
  }
}

.learning-space__error {
  background: $brand-neutral-0;
  padding-top: 10px;
  margin: 0px 46px 0px 46px;
  border-radius: 8px;
  background: $brand-neutral-0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);

  .ls-wrapper {
    box-shadow: none;
    border-radius: 0px;
  }
}

.learning-space__breadcrumb {
  padding: 10px 0px 0px 20px;
  margin-left: 10px;
}
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $brand-neutral-0;
  z-index: 99;
  @include flex-horizontal-center;
}
</style>
